<template>
  <!--  <div class="home">Under Development</div>-->
  <img
    src="../assets/logo.png"
    style="max-width: 30vh"
    alt="IIID Nashik Logo"
  />
  <img
    src="../assets/designXlogo.png"
    style="max-width: 30vh"
    alt="Event Logo"
  />
  <br /><br />
  <h2>IIID - Interior and Building Material Exhibition & Fest 2023</h2>
  <br />
  <h3>Attendee Registration</h3>
  <br />
  <v-sheet width="300" class="mx-auto">
    <v-form ref="form" @submit.prevent>
      <v-text-field
        v-model="fullName"
        :rules="fullNameRule"
        label="Full Name*"
      ></v-text-field>
      <v-text-field
        v-model="email"
        :rules="emailRule"
        label="Email Address*"
      ></v-text-field>
      <v-text-field
        v-model.number="contactNumber"
        :rules="contactNumberRule"
        label="Mobile Number*"
      ></v-text-field>
      <v-combobox
        clearable
        label="Designation*"
        v-model="designation"
        :rules="designationRule"
        :items="[
          'Architect',
          'Interior designer',
          'Engineer',
          'Professional',
          'Business Owner',
          'Home Owner',
        ]"
      ></v-combobox>
      <v-text-field
        v-model="reference"
        :rules="refRule"
        label="Reference*"
      ></v-text-field>
      <v-btn type="submit" block class="mt-3" v-on:click="submit"
        ><b>Submit</b></v-btn
      >
    </v-form>
    <br />
    <v-alert
      :text="errorText"
      v-if="errorCheck"
      type="error"
      variant="outlined"
    ></v-alert>
  </v-sheet>
  <br />
  <div>
    <div>
      <h2>Registration Partner</h2>
      <br />
      <img
        class="principal_partners"
        src="../assets/mangal_cloth_stores.png"
        alt="Principal partner VIVA"
      />
    </div>
    <div>
      <h2>Principal Partners</h2>
      <br />
      <img
        class="principal_partners"
        src="../assets/Viva.jpg"
        alt="Principal partner VIVA"
      />
      <img
        class="principal_partners"
        src="../assets/greenlam.jpg"
        alt="Principal partner Greenlam"
      />
    </div>
    <hr style="max-width: 50vw; margin-left: 25%; height: 2px" />
    <div>
      <h2>Diamond Partners</h2>
      <br />
      <img
        class="diamond_partners"
        src="../assets/sunbrd.jpg"
        alt="Diamond partner SunBird"
      />
      <img
        class="diamond_partners"
        src="../assets/DG.jpg"
        alt="Diamond partner DeepGiri"
      />
      <img
        class="diamond_partners"
        src="../assets/nexon.jpg"
        alt="Diamond partner Nexion"
      />
    </div>
    <hr style="max-width: 50vw; margin-left: 25%; height: 2px" />
    <div>
      <h2>Gold Partners</h2>
      <br />
      <img
        class="gold_partners"
        src="../assets/anand.jpg"
        alt="Gold partner Anand Ceramics"
      />
      <img
        class="gold_partners"
        src="../assets/window.jpg"
        alt="Gold partner Window Expert"
      />
      <img
        class="gold_partners"
        src="../assets/naby.jpg"
        alt="Gold partner NabyTek"
      />
      <img
        class="gold_partners"
        src="../assets/AGL.jpg"
        alt="Gold partner AGL"
      />
    </div>
    <hr style="max-width: 50vw; margin-left: 25%; height: 2px" />
    <div>
      <h2>Media Partners</h2>
      <img
        class="media_partners"
        src="../assets/guru.jpg"
        alt="Media Partners GURU Publicity"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      errorText: "",
      errorCheck: false,
      fullName: "",
      fullNameRule: [
        (v) => String(v)?.length > 0 || "Full name cannot be empty",
        (v) => /^[A-Z+ a-z]+$/.test(v) || "Only Alphabets Please",
      ],
      email: "",
      emailRule: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      contactNumber: "",
      contactNumberRule: [
        (v) => String(v)?.length > 0 || "Mobile Number cannot be empty",
        (v) => Number.isInteger(Number(v)) || "The value must be a number",
        (v) => v > 0 || "The value must be greater than zero",
        (v) => String(v)?.length <= 12 || "Mobile Number too big",
        (v) => String(v)?.length >= 10 || "Mobile Number too short",
      ],
      designation: null,
      designationRule: [
        (v) => v !== null || "Please State Your Designation",
        (v) => v.length > 0 || "Please State Your Designation",
        (v) => /^[A-Z+ a-z]+$/.test(v) || "Only Alphabets Please",
      ],
      reference: "",
      refRule: [(v) => String(v)?.length > 0 || "Reference cannot be empty"],
    };
  },
  methods: {
    async submit() {
      const formVaildity = await this.$refs.form.validate();
      if (formVaildity.valid) {
        const requestConfig = {
          method: "post",
          url: "https://api.iiidnashik.in/data_in",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            mobile: String(this.contactNumber).startsWith("91")
              ? String(this.contactNumber).substring(2)
              : String(this.contactNumber),
            email: this.email,
            designation: this.designation,
            ref: this.reference,
            name: this.fullName,
            reg_type: "Online",
            payment: "No Payment",
          }),
        };
        const that = this;
        return axios(requestConfig)
          .then((response) => {
            this.$router.push("/thanks");
            return response.data;
          })
          .catch(function (response) {
            if (response.response.status === 500)
              that.errorText =
                "Internal Server Error. Please inform IIID Nashik Team";
            else that.errorText = response.response.data;
            that.errorCheck = true;
            setInterval(function () {
              that.errorCheck = false;
              that.errorText = "";
            }, 6000);
            console.log(response);
          });
      }
    },
  },
};
</script>

<style scoped>
.home {
  font-size: 50px;
}

.principal_partners {
  max-width: 25vh;
  padding: 10px 20px 10px;
}

.diamond_partners {
  max-width: 25vh;
  padding: 10px 20px 10px;
}

.gold_partners {
  max-width: 25vh;
  padding: 10px 20px 10px;
}

.media_partners {
  max-width: 25vh;
  padding: 10px 20px 10px;
}
</style>
